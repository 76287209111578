<template>
    <v-list two-line>
        <v-divider></v-divider>
        <template v-for="(item, index) in selectedOrder.items">
            <v-list-item :key="`item-${index}`" class="px-0">
                <v-list-item-content>
                    <v-list-item-title class="subtitle-2 text-left">
                        {{ `${index+1} - ${item.vd2produto} ${item.pr0desc}` | capitalize }}
                    </v-list-item-title>

                    <v-list-item-subtitle
                        class="text--grey caption text-left"
                    >{{ `${item.cr1nome} - ${item.gr1desc}` | capitalize }}</v-list-item-subtitle>

                    <v-list-item-subtitle
                        class="text-left"
                    >Quantidade: {{ item.vd3qtde }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action class="align-self-end mb-0">
                    <v-list-item-action-text
                        class="body-2 font-weight-bold"
                        :style="{ color: parameters.primaryColor }"
                    >
                        {{ item.vd5preco | money }}
                    </v-list-item-action-text>
                </v-list-item-action>
            </v-list-item>

            <v-divider
                v-if="index < selectedOrder.items.length - 1"
                :key="index"
            ></v-divider>
        </template>
    </v-list>
</template>

<script>
export default {
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        selectedOrder: {
            get() {
                return this.$store.state.order.selectedOrder;
            },
        },
    },
};
</script>

<style>
</style>