<template>
    <v-main style="min-height: 80% !important;" >
        <v-container class="pb-12">
            <v-row class="">
                <v-col cols="12" class="py-0">
                    <h2
                        :style="{ color: parameters.primaryColor }"
                        class="d-flex align-start mt-3"
                    >
                        Pedido <span style="color: grey" class="pl-2">#{{selectedOrder.vd1pedido}}</span>
                        <v-spacer></v-spacer>
                        <v-chip v-if="$mq != 'sm'" :color="statusColor" :class="selectedOrder.vd1status == parameters.store.b2bpedpro || selectedOrder.vd1status == parameters.store.b2bpedcan || selectedOrder.vd1status == parameters.store.b2bpedent ? 'white--text' : ''">
                            Pedido {{ selectedOrder.vd1statdes }}
                        </v-chip>
                    </h2>
                    <p class="ma-0 grey--text text-left">Criado em {{ selectedOrder.orderDate }} via {{ selectedOrder.vd1ecomped ? "loja virtual" : "sistema de gestão"}}</p>
                </v-col>
                <v-col v-if="$mq == 'sm'" cols="12">
                    <v-chip :color="statusColor" :class="selectedOrder.vd1status == parameters.store.b2bpedpro || selectedOrder.vd1status == parameters.store.b2bpedcan || selectedOrder.vd1status == parameters.store.b2bpedent ? 'white--text' : ''">
                        Pedido {{ selectedOrder.vd1statdes }}
                    </v-chip>
                </v-col>
                <v-col cols="12" class="mt-6">
                    <v-card
                        width="100%"
                        elevation="1"
                    >
                        <v-row class="px-7 pt-4">
                            <v-col cols="12" sm="6" class="d-flex justify-center">
                                <v-card elevation="0" class="headline outlinedCard" width="100%">
                                    <v-card-title class="grey--text">Representante/Guia <v-spacer></v-spacer> <v-icon color="primary">mdi-bus</v-icon> </v-card-title>
                                    <v-card-text class="text-left">{{ selectedOrder.cf6nome }}</v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="6" class="d-flex justify-center">
                                <v-card elevation="0" class="headline outlinedCard"  width="100%">
                                    <v-card-title class="grey--text">Frete<v-spacer></v-spacer><span>{{ selectedOrder.vd1vlfrete | money}}</span></v-card-title>
                                    <v-card-text>
                                        <p class="text-left mb-0 font-weight-bold">{{ selectedOrder.cf7nome }}</p>
                                        <p class="text-left mb-0" v-if="selectedOrder.shippingDate">Embarque: {{ selectedOrder.shippingDate | shortDate }}</p>
                                        <p class="text-left mb-0" v-if="new Date(selectedOrder.vd1entrega) > new Date('1900-01-01')">Entrega: {{ selectedOrder.vd1entrega | shortDate }}</p>
                                        <p class="text-left mb-0" v-else>Entrega: Sem previsão</p>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="6" class="d-flex justify-center">
                                <v-card elevation="0" class="headline outlinedCard"  width="100%">
                                    <v-card-title class="grey--text">Pagamento<v-spacer></v-spacer><v-icon color="primary">mdi-currency-usd</v-icon> </v-card-title>
                                    <v-card-text class="text-left">{{selectedOrder.cv2nome}} {{ selectedOrder.vd4nome }}</v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="6" class="d-flex justify-center">
                                <v-card elevation="0" class="headline outlinedCard" width="100%">
                                    <v-card-title class="grey--text">Observações<v-spacer></v-spacer> <v-icon color="primary">mdi-text</v-icon> </v-card-title>
                                    <v-card-text class="text-left">{{ selectedOrder.vd1observa }}</v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-list dense class="pt-7">
                            <v-subheader class="font-weight-bold text-h5">Produtos</v-subheader>
                            <v-list-item>
                                <v-list-item-content>
                                    <OrderItemMobile v-if="$mq != 'lg'"/>
                                    <OrderItemDesktop v-else/>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <v-divider class="mt-5 mb-7"></v-divider>
                        <v-card-subtitle class="py-1 d-flex justify-start align-center"><span>Total dos produtos:</span> <v-spacer></v-spacer> <span class="font-weight-bold">{{ selectedOrder.vd1total |money }}</span> </v-card-subtitle>
                        <v-card-subtitle v-if="selectedOrder.vd1vlfrete" class="py-1 d-flex justify-start align-center"><span>Frete:</span> <v-spacer></v-spacer> <span class="font-weight-bold">{{ selectedOrder.vd1vlfrete |money }}</span> </v-card-subtitle>
                        <v-card-subtitle class="py-1 d-flex justify-start align-center"><span>Descontos:</span><v-spacer></v-spacer> <span class="font-weight-bold">{{ (selectedOrder.vd1vlfrete + selectedOrder.vd1total) * selectedOrder.vd1descon /100 | money }} ({{ selectedOrder.vd1descon | percent }})</span> </v-card-subtitle>
                        <v-card-title class="text-h5">Total: <v-spacer></v-spacer> <span class="font-weight-bold">{{ selectedOrder.vd1vlfrete + selectedOrder.vd1total - ((selectedOrder.vd1vlfrete + selectedOrder.vd1total) * selectedOrder.vd1descon /100) | money }}</span> </v-card-title>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
const OrderItemMobile = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/order/OrderItemMobile.vue"
    );
const OrderItemDesktop = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/order/OrderItemDesktop.vue"
    );

export default {
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        order: {
            get() {
                return this.$store.state.order;
            },
        },
        selectedOrder: {
            get() {
                return this.$store.state.order.selectedOrder;
            },
        },
        statusColor(){
            switch (this.selectedOrder.vd1status) {
                case this.parameters.store.b2bpedpen:
                    return "grey lighten-1"
                case this.parameters.store.b2bpedpro:
                    return "light-blue darken-4"
                case this.parameters.store.b2bpedtra:
                    return "yellow accent-2"
                case this.parameters.store.b2bpedcan:
                    return "red darken-1"
                case this.parameters.store.b2bpedent:
                    return "#34DBA1"
                default:
                    return "primary"
            }
            
        }
    },
    components:{
        OrderItemMobile,
        OrderItemDesktop
    },
    mounted(){
        if (this.$route.params.vd1pedido) {
            this.$store.dispatch("order/showOrder", this.$route.params.vd1pedido)
        }
    }
};
</script>

<style>
</style>